.card {
  width: 12rem;
  height: auto;
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
  position: absolute;
  text-align: center;
  border: 4px solid var(--orangeCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  padding: 0px 26px;
  box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.302);
  background: rgba(255, 255, 255, 0.26);
}

.card > img {
  transform: scale(0.9);
}
.card span:nth-of-type(1) {
  color: var(--black);
  font-size: 18px;
  font-weight: bold;
  font-family: "Nunito";
}
.card span:nth-of-type(2) {
  color: var(--gray);
  font-size: 14px;
  font-family: "Nunito";
}
.c-btn {
  background: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: rgb(89, 140, 174);
  box-shadow: 0px 19px 60px rgba(0, 0, 0, 0);
}

@media screen and (max-width: 620px) {
  .card {
    position: relative;
    width: auto;
    transform: scale(0.8);
  }
  .card > img {
    transform: scale(0.7);
  }
}
