.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  color: #115169;
  margin: 15% auto;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
  max-height: 80vh;
}

/* Close button styles */
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #27647a;
  color: #fff;
  border: none;
  border-radius: 20%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
}

.close:hover {
  background: #ef8323;
}

/* Text inside the modal */
.footer-modal-text h3,
.footer-modal-text h4 {
  margin-bottom: 0;
  color: #1493d2;
}

.footer-modal-text p {
  margin: 5px 0;
  line-height: 1.6;
}

.footer-modal-text ul {
  margin: 0;
  padding-left: 1.5em;
}

.footer-modal-text ul li {
  margin-bottom: 0.5em;
}
