.portfolio {
  min-height: 100vh;
  margin-bottom: 8rem;
}
/* Heading for the portfolio section */
.portfolio-heading {
  position: relative;
  margin-bottom: 3rem;
}
.p-heading {
  display: block;
  font-family: "ConcertOne-Regular";
  font-size: 2rem;
  margin-bottom: 1rem;
}
.projectDetails {
  display: block;
  color: var(--gray);
  font-family: "ConcertOne-Regular";
  font-size: 1.3rem;
}
.projectTitle {
  color: rgb(33, 130, 179);
  font-size: 1rem;
  font-family: "ConcertOne-Regular";
}

/* Container for the project cards */
.portfolio-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
}
.lazyImg {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.lazyImg.loaded {
  opacity: 1;
}
/* Individual project card */
.portfolio-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  filter: drop-shadow(-12px 15px 13px rgba(131, 131, 131, 0.35));
  overflow: hidden;
  width: 245px;
  height: 260px;
  padding: 0 0.5rem;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.portfolio-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.portfolio-card a {
  text-decoration: none;
}

.portfolio-card h3:hover {
  color: orange;
}

/* Nested swiper within each project card */
.nested-swiper {
  width: 100%;
  height: 200px;
  position: relative;
}

/* Ensure slides fill the container */
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Ensure images fit well in the slides */
.swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: fill;
}

/* Active pagination bullet */
.swiper-pagination-bullet-active {
  opacity: 1;
  align-items: center;
  background: var(--orange, #ff6600);
}

.swiper-pagination-bullets {
  position: absolute;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  bottom: -0.4rem !important;
  height: 1.5rem;
}
.swiper-pagination {
  width: 80% !important;
  left: 10% !important; 
  margin: 0 auto !important; /* Center horizontally */
}


.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--orange)) !important;
}

/* Specific targeting for the MovieList project image */
.cardImgMovie {
  width: auto;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 620px) {
  .portfolio-cards {
    justify-content: center;
  }
  .portfolio-heading {
    text-align: center;
    justify-content: center;
  }
  .p-heading {
    display: block;
    font-family: "ConcertOne-Regular";
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .projectDetails {
    display: block;
    color: var(--gray);
    font-family: "ConcertOne-Regular";
    font-size: 1rem;
  }
}
