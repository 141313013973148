.t-wrapper {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  filter: drop-shadow(-12px 15px 13px rgba(131, 131, 131, 0.35));
  border-radius: 20px;
  overflow: hidden;
  padding: 2rem 1rem;
}

.t-heading {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 1.7rem;
  color: var(--gray);
  font-family: "ConcertOne-Regular";
  margin-bottom: 2rem;
  position: relative;
  flex-direction: column;
}
.t-heading > img {
  height: auto;
  width: 6rem;
  margin-top: 1rem;
}
.blur {
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  filter: blur(180px);
}

.t-blur1 {
  right: -5rem;
  top: -10rem;
}

.t-blur2 {
  bottom: -18rem;
  left: -5rem;
}
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  border: 5px solid var(--blueCard);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.123);
}
.testimonial > img {
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
}
.t-heading > img {
  width: 6rem;
  height: 6rem;
}
.testimonial > span {
  color: var(--gray);
  font-family: Nunito;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.t-wrapper .swiper {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.t-wrapper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 620px) {
  .t-wrapper {
    padding: 1rem;
    min-height: auto;
  }
  .t-heading {
    flex-direction: column;
    font-size: 1.2rem;
  }
  .t-heading > img {
    width: 4rem;
  }
  .testimonial {
    padding: 1.5rem;
    font-size: 0.9rem;
    height: 450px;
  }
}
@media screen and (max-width: 320px) {
  .testimonial {
    padding: 1.5rem;
    font-size: 0.9rem;
    height: 500px;
  }
}
