.n-wrapper {
  position: fixed;
  display: flex;
  font-family: "Nunito";
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 0;
  z-index: 9999;
  padding: 1rem 3rem;
  color: rgb(218, 233, 247);
  background-color: var(--black);
  box-shadow: var(--boxShadow);
  /* margin-bottom: 6rem; */
}

.n-left,
.n-center,
.n-right {
  display: flex;
  align-items: center;
}

.n-name {
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--orange);
  margin-right: 1rem;
}

.n-center ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 700;
}

.n-center li {
  margin: 0 1rem;
  font-size: 1rem;
  cursor: pointer;
}

.n-center li a {
  text-decoration: none;
  color: inherit;
}

.n-center li a:hover {
  color: var(--orange);
}

.n-right .contactBtn {
  padding: 0.5rem 1.5rem;
  background-color: var(--orange);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.n-right .contactBtn:hover {
  background-color: rgb(239, 189, 10);
}

.hamburger {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 840px) {
  .hamburger {
    display: flex;
    align-items: center;
    order: 1;
  }

  .n-center {
    position: absolute;
    top: 6vh;
    right: 0;
    color: rgb(28, 115, 152);
    background-color: rgb(231, 237, 240);
    width: 20%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border-radius: 5px;
  }

  .n-center.open {
    display: flex;
  }

  .n-center ul {
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .n-center li {
    margin: 0.5rem 0;
  }

  .n-right {
    display: flex;
    flex: 1;
    margin-right: 1rem;
    justify-content: flex-end;
  }
  .contactBtn {
    padding: 5px 10px !important;
    font-size: 0.7rem !important;
  }
}

@media screen and (max-width: 520px) {
  .n-wrapper {
    padding: 1rem 2rem;
  }

  .n-name {
    font-size: 0.8rem;
    font-weight: 900;
    color: var(--orange);
    margin-right: 1rem;
  }

  .n-center ul li a {
    font-size: 0.7rem;
  }
  .n-center.open {
    margin-top: 2rem;
  }
  .n-right .contactBtn {
    font-size: 0.6rem;
    padding: 0.5rem 0.5rem !important;
  }
}
