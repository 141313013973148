:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #0d2631e8;
  --gray: #788097;
  --skyblue: #8ebade;
  --blueCard: #ddf8fe;
  --purple: rgb(238 210 255);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --orangeCard: rgba(252, 166, 31, 0.45);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}

/* Font Weights */
.thin {
  font-weight: 100;
}

.extra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}
