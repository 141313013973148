@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "ConcertOne-Regular";
  src: url("./fonts/ConcertOne-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PaytoneOne-Regular";
  src: url("./fonts/PaytoneOne-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Rowdies Font */
@font-face {
  font-family: "Rowdies-Light";
  src: url("./fonts/Rowdies-Light.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Rowdies-Regular";
  src: url("./fonts/Rowdies-Regular.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Rowdies-Bold";
  src: url("./fonts/Rowdies-Bold.ttf") format("truetype");
  font-weight: 800;
}
