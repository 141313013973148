.work {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  gap: 2rem;
  overflow: hidden;
  padding: 0 0.1rem;
}

.w-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.w-mainCircle {
  width: 20rem;
  height: 20rem;
  background: transparent;
  border-radius: 100%;
  box-shadow: var(--smboxShadow);
  position: relative;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
}
.w-backCircle {
  position: absolute;
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  z-index: -1;
}
.w-mainCircle > * {
  position: absolute;
}

.w-mainCircle > :nth-child(1) {
  top: -3rem;
  left: 6rem;
}
.w-mainCircle > :nth-child(2) {
  left: -3rem;
  top: 5rem;
}
.w-mainCircle > :nth-child(3) {
  left: 6rem;
  top: 5rem;
}
.w-mainCircle > :nth-child(4) {
  left: 15rem;
  top: 5rem;
}
.w-mainCircle > :nth-child(5) {
  left: 6rem;
  top: 13rem;
}

.w-secCircle,
.w-secCircle2,
.w-secCircle3 {
  width: 9rem;
  height: 9rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.w-secCircle > img {
  transform: scale(0.3);
}
.w-secCircle2 > img {
  transform: scale(0.1);
}
.w-secCircle3 > img {
  transform: scale(0.3);
}
.blueCircle {
  background: #e2f2ff;
  right: calc(50% - 4.5rem);
  top: calc(50% - 5rem);
}

.yellowCircle {
  background: #ebb76f;
  right: calc(50% - 5rem);
  top: calc(50% - 3rem);
}

.left {
  flex: 1;

  position: relative;
}

@media screen and (max-width: 1320px) {
  .w-right {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 1024px) {
  .w-right {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 768px) {
  .w-right {
    transform: scale(0.7);
  }
  .left > :nth-child(5) {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 620px) {
  .work {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
  .w-right {
    transform: scale(0.6);
  }

  .left {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}
