.contact-form {
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-top: 8rem;
  position: relative;
  overflow: visible;
  filter: drop-shadow(-12px 15px 13px rgba(131, 131, 131, 0.35));
}

.contact-form .form-content {
  position: relative;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.contact-form .form-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  color: #1c6073;
}

.contact-form .form-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #1c6073;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.1) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: -1;
}

form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 10px;
  height: 80%;
  align-items: center;
  border-radius: 8px;
  color: #1b495d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
}

.c-right {
  display: flex;
  justify-content: center;
  position: relative;
  flex: 1;
}

.c-right > form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  height: 80%;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.c-right > form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  z-index: -1;
}

.c-right .user {
  width: 100%;
  height: 2.5rem;
  padding: 0.75rem;
  border: 1px solid rgb(215, 215, 215) !important;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 15px;
  color: #0c5270;
}

textarea {
  height: 20rem !important;
  padding: 0.75rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 15px;
  color: #0c5270;
}

.c-right .user:focus {
  color: #1b495d;
  border: 1px solid #0c5270;
  box-shadow: 0 0 5px #0c5270;
}

.submitBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-right: 1rem;
  margin-top: -1rem;
}

.button {
  padding: 0.75rem;
  background-color: var(--orange);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  width: 130px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #2fa2e4;
}

.user::placeholder {
  color: #1c6073;
  opacity: 1;
}

.user:-ms-input-placeholder {
  color: #1c6073;
}

.user::-ms-input-placeholder {
  color: #1c6073;
}

.c-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.contact-heading {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--gray);
  font-family: "ConcertOne-Regular";
}

.floating-emailIcon {
  position: relative;
}

.contactMe {
  font-size: 1.7rem;
  color: var(--orange);
  margin-top: 1rem;
}

.floatingEmail > img {
  width: 300px;
  height: auto;
}

@media screen and (max-width: 1200px) {
  .contact-form {
    flex-direction: column;
    align-items: flex-start;
  }

  .c-right {
    width: 100%;
  }

  .c-right > form {
    width: 100%;
  }
  .c-left {
    width: 100%;
    height: 20%;
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: center;
  }

  .contact-heading {
    display: flex;
    width: 100%;
    min-width: 200px;
  }

  .contact-heading span {
    display: block;
    display: inline-block;
  }

  .floatingEmail > img {
    transform: scale(0.6);
  }
}

@media screen and (max-width: 770px) {
  .contact-form {
    flex-direction: column;
    align-items: flex-start;
  }
  .contact-heading span {
    font-size: 1.5rem;
  }
  .floatingEmail > img {
    transform: scale(0.5);
  }
  .contact-heading {
    display: flex;
    width: 100%;
    min-width: 150px;
  }
}

@media screen and (max-width: 520px) {
  .contact-form {
    flex-direction: column;
    width: auto;
    align-items: flex-start;
  }
  .contact-heading span {
    font-size: 1.1rem;
  }
  .floatingEmail > img {
    transform: scale(0.4);
    margin-left: -7rem;
  }
  .c-right .user {
    font-size: 0.8rem;
  }
  .contact-heading {
    display: flex;
    width: 100%;
    min-width: 100px;
  }
  .button {
    width: 30%;
    font-size: 0.8rem;
  }
}
