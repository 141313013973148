.experience {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  min-height: 100vh;
  margin-bottom: 5rem;
  margin-top: 5rem;
  color: var(--gray);
  padding: 0 0.1rem;
  overflow: hidden;
  font-family: "ConcertOne-Regular";
}
.leftE {
  min-height: 50vh;
}
.rightE {
  min-height: 50vh;
}
.achievement {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.achievement span:nth-of-type(2) {
  color: var(--orange);
}

.circle {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 4rem;
  height: 4rem;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 10px 20px rgba(63, 63, 63, 0.3);
}

.circle::before {
  content: "";
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  position: absolute;
  z-index: -1;
  background-image: linear-gradient(to bottom, #02d1ff 0%, #ffe101 100%);
  border-radius: 50%;
  box-shadow: var(--boxShadow);
}

.rightE > img {
  width: auto;
  height: 500px;
}
@media screen and (max-width: 1320px) {
  .experience {
    margin-top: 5rem;
    margin-bottom: 10rem;
    flex-direction: column;
    min-height: 80vh;
  }
  .leftE {
    display: flex;
    min-height: auto;
  }
  .rightE {
    display: flex;
    min-height: auto;
  }
  .rightE > img {
    transform: scale(0.9);
  }
}
@media screen and (max-width: 620px) {
  .experience {
    min-height: auto;
    margin-top: 5rem;
    margin-bottom: 10rem;
    flex-direction: column;
  }
  .leftE {
    display: flex;
    min-height: auto;
  }
  .rightE {
    display: flex;
    min-height: auto;
  }
  .circle {
    width: 3rem;
    height: 3rem;
    font-size: 15px;
    padding: 10px;
  }
  .leftE {
    display: block;
    font-size: 15px;
  }
  .rightE > img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
