.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.popup-image {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.popup-message {
  font-size: 16px;
  color: #2d5d71;
  margin-bottom: 20px;
  font-family: "ConcertOne-Regular";
}

.popup-close-button {
  background-color: #34c3eb;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
