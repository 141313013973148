.services {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  gap: 2rem;
  position: relative;
  margin-top: 5rem;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.left > :nth-child(1) {
  color: var(--gray);
  font-family: "ConcertOne-Regular";
  font-size: 2.5rem;
}

.left > :nth-child(3) {
  color: var(--orange);
  font-family: "ConcertOne-Regular";
  font-size: 1.5rem;
}

.left > :nth-child(5) {
  color: var(--gray);
  font-family: "ConcertOne-Regular";
  font-size: 1rem;
}

.buttonDiv {
  width: 50%;
  margin-top: 2rem;
}

.serviceBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
  color: white;
  font-family: "ConcertOne-Regular";
  background-color: var(--orange);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 5px 8px 30px rgba(63, 63, 63, 0.3);
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.serviceBtn:hover {
  background-color: rgb(239, 189, 10);
}
.buttonDiv span {
  margin-right: 0.5rem;
}
.cards {
  display: flex;
  flex: 2;
  justify-content: space-around;
  position: relative;
  width: 100%;
  max-width: 100vw;
}
.card-technologies,
.card-performance,
.card-security,
.card-speed,
.card-efficiency,
.card-innovation {
  position: absolute;
  width: 100%;
}
@media screen and (max-width: 1320px) {
  .services {
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-top: 10rem;
    height: 100vh;
  }
  .cards {
    display: flex;
    flex: 2;
    position: relative;
    width: 100%;
    gap: 2rem;
    justify-content: center;
  }
  .buttonDiv {
    width: 30%;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .card-speed {
    top: 45% !important;
  }
  .card-efficiency {
    top: 55% !important;
  }
  .card-innovation {
    top: 62% !important;
  }
}
@media screen and (max-width: 620px) {
  .services {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    position: relative;
    margin-top: 10rem;
  }
  .cards {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .cards > * {
    position: static;
    width: 80%;
  }
  .left > :nth-child(1) {
    color: var(--gray);
    font-family: "ConcertOne-Regular";
    font-size: 1.5rem;
  }
  .left > :nth-child(3) {
    color: var(--orange);
    font-family: "ConcertOne-Regular";
    font-size: 1.2rem;
  }
  .left > :nth-child(5) {
    color: var(--gray);
    font-family: "ConcertOne-Regular";
    font-size: 0.8rem;
  }
  .buttonDiv {
    width: 50%;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .serviceBtn {
    font-size: 0.7rem;
  }
}
