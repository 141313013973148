footer {
  position: relative;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: end;
  overflow: hidden;
  width: 100%;
  height: 50vh;
  font-family: "Nunito";
  background: url("../../img/waves/wave9.svg") no-repeat center bottom;
  background-size: cover;
  z-index: 1;
}

html {
  scroll-behavior: smooth;
}

.footer-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 80%;
}

.footerTop {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
}

.contactInfo,
.links-container ul {
  list-style: none;
  padding: 0;
}

.footerBottom {
  text-align: center;
}

.contact-info h4 {
  padding-left: 2px;
}

.quick-links {
  display: block;
}

.quick-links h4 {
  margin-bottom: 15px;
}

.quick-links .links-container {
  display: flex;
  justify-content: space-between;
}

.quick-links ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.quick-links ul li a {
  text-decoration: none;
}

.quick-links .leftQ,
.quick-links .rightQ {
  flex: 1;
  padding-right: 20px;
}

.footer-section h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: rgb(255, 255, 255);
}

.footer-section p,
a,
.footer-section ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0.9rem;
}

.contactInfo p,
a {
  padding: 0.2rem;
}
.contactInfo a {
  text-decoration: none;
  color: white;
}
.footer-section ul {
  margin-top: 0.5rem;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: var(--skyblue);
}

.social-icons {
  display: flex;
  gap: 1.3rem;
  margin-top: 1.5rem;
}
.social-icons a {
  color: #ffffff;
}

.social-icons img {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.social-icons a:hover img {
  transform: scale(1.2);
}
.newsletterForm {
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  backdrop-filter: none !important;
}
.newsletter input[type="email"] {
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  width: 100%;
}

.newsletterBtn {
  padding: 0.5rem 1rem;
  background-color: var(--skyblue);
  color: #ffffff;
  border: none;
  display: flex;
  margin-right: 0 !important;
  margin-top: -1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletterBtn:hover {
  background-color: #2fa2e4;
}
.legal {
  margin-bottom: 1rem;
}
.legal ul {
  display: flex;
  gap: 1rem;
}

.legal ul li a {
  font-size: 0.8rem;
}

.tagline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: bold;
  color: vwhite;
  text-align: center;
  width: 100%;
}
.tagline ul li a {
  color: rgb(215, 215, 215);
  text-decoration: none;
  list-style: none;
}
.tagline ul {
  list-style: none;
  padding-left: 0;
}

.tagline ul li {
  list-style: none;
}

.tagline ul li a {
  text-decoration: none;
}
.tagline p {
  color: #4a637b;
}

@media screen and (max-width: 1200px) {
  footer {
    position: relative;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 80vh;
    font-family: "Nunito";
    background: url("../../img/waves/wave9.svg") no-repeat center bottom;
    background-size: cover;
    z-index: 1;
    align-items: end;
    padding: 0.5rem 0;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 80%;
  }

  .footerTop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 1rem;
  }

  .footer-section {
    flex: 1;
    min-width: 200px;
  }

  .contactInfo,
  .links-container ul {
    list-style: none;
    padding: 0;
  }

  .footerBottom {
    text-align: center;
  }
  .newsletter input[type="email"] {
    width: 50%;
    margin: 0 auto;
  }
  .tagline {
    font-size: 0.9rem;
  }
  .newsletterBtn {
    width: auto;
    display: flex;
    text-align: center !important;
    align-items: center;
    justify-self: center;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 992px) {
  footer {
    position: relative;
    color: #ffffff;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: end;
    font-family: "Nunito";
    background: url("../../img/waves/wave9.svg") no-repeat center bottom;
    background-size: cover;
    z-index: 1;
    height: 80vh;
    padding: 0.5rem 0;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 80%;
  }

  .footerTop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 1rem;
  }

  .footer-section {
    flex: 1;
    min-width: 200px;
  }

  .contactInfo,
  .links-container ul {
    list-style: none;
    padding: 0;
  }

  .footerBottom {
    text-align: center;
  }
  .newsletter input[type="email"] {
    width: 50%;
  }

  .newsletterBtn {
    width: 100px;
  }
  .newsletterBtn {
    width: auto;
    display: flex;
    text-align: center !important;
    align-items: center;
    justify-self: center;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 768px) {
  footer {
    position: relative;
    color: #ffffff;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: end;
    font-family: "Nunito";
    background: url("../../img/waves/wave9.svg") no-repeat center bottom;
    background-size: cover;
    z-index: 1;
    height: 105vh;
    padding: 0.5rem 0;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 80%;
  }

  .footerTop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  .footer-section {
    flex: 1;
    min-width: 200px;
  }

  .contactInfo,
  .links-container ul {
    list-style: none;
    padding: 0;
  }

  .footerBottom {
    text-align: center;
  }

  .legal ul {
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    justify-content: center;
  }
}

@media screen and (max-width: 530px) {
  footer {
    margin-top: 5rem;
    position: relative;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: end;
    font-family: "Nunito";
    background: url("../../img/waves/wave.svg") no-repeat center bottom;
    background-size: cover;
    z-index: 1;
    height: 120vh;
    padding: 0.5rem 0;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 80%;
  }

  .footerTop {
    padding-top: 5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  .footer-section {
    flex: 1;
    min-width: 200px;
  }

  .contactInfo,
  .links-container ul {
    list-style: none;
    padding: 0;
  }

  .footerBottom {
    text-align: center;
  }
  .newsletter input[type="email"] {
    width: 80%;
  }

  .newsletterBtn {
    width: auto;
    display: flex;
    text-align: center !important;
    align-items: center;
    justify-self: center;
    margin: 0 auto !important;
  }

  .legal ul {
    width: 100%;
  }

  .tagline {
    font-size: 0.8rem;
    width: 90%;
  }

  .social-icons {
    justify-content: center;
    gap: 1rem;
  }
}
